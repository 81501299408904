<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">عرض المنتج</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                    <tr>
                      <th>الاسم</th>
                      <td>{{ product.name }}</td>
                    </tr>
                    <tr>
                      <th>الاسم بالعربي</th>
                      <td>{{ product.name }}</td>
                    <tr>
                      <th>الفئة الرئيسية</th>
                      <td>
                          {{ selected_category.name }}
                      </td>
                    </tr>
                    <tr>
                      <th>الفئة الفرعية</th>
                      <td>
                          {{ selected.name }}
                      </td>
                    </tr>
                    <tr>
                        <th>الصور</th>
                        <td>
                      <br />
                      <CCol
                        sm="3"
                        v-for="(url, index) in JSON.parse(product.images)"
                        :key="index"
                      >
                        <div class="form-group">
                          <img
                            fluid
                            v-bind:src="url"
                            class="img-rounded"
                            style="height: 100px; width: 100px"
                          />
                        </div>
                      </CCol>
                        </td>
                    </tr>
                    
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-bordered">
                    <tr>
                      <th>السعر</th>
                      <td>{{ product.price | number }} QAR</td>
                    </tr>
                    <tr>
                      <th>الماركة</th>
                      <td>{{ brand.name }}</td>
                    </tr>
                    <tr>
                      <th>التخفيض</th>
                      <td><span v-if="product.discount">{{ product.discount }} %</span><span v-else>0</span></td>
                    </tr>
                    <tr>
                      <th>الوصف</th>
                      <td>{{ product.description }}</td>
                    </tr>
                    <tr>
                      <th>الوصف بالعربي</th>
                      <td>{{ product.ar_description }}</td>
                    </tr>
                </table>
              </div>
            </div>
            <br />
            <CButton color="warning" @click="goBack">رجوع</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
export default {
  name: "EditProduct",
  data() {
    return {
      product: [],
      image_1: "",
      image_2: "",
      image_3: "",
      image_4: "",
      image_5: "",
      categories: [],
      subCategories: [],
      selected: "",
      selected_area: "",
      brand: "",
      selected_city: "",
      selected_category: "",
      description: [],
      brands: [],
      images: [],
    };
  },
  created() {
    this.$http
      .get(`${process.env.VUE_APP_URL}products/${this.$route.params.id}`)
      .then((response) => {
        this.product = response.data.data;
        this.selected = this.product.sub_category;
        this.selected_category = this.product.category;
        this.brand = this.product.brand;
      });
  },
  methods: {
    goBack() {
      this.productsOpened
        ? this.$router.go(-1)
        : this.$router.push({
            path: "/products",
          });
    },
  },
};
</script>

<style scoped>
th {
    background-color: #3c4b64;
    color: #fff;
}
</style>
